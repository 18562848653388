@import "styles/main.scss";

.container {
  padding: 2rem;
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  @include medium-devices {
    padding: 10rem 2rem;
    max-width: 120rem;
  }
  @include large-devices {
    padding: 20rem 2rem;
    max-width: 120rem;
  }
}
